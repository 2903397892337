import React from "react";
import Layout from "@components/kaigai-fx/layout"
import FixedFoot from "@components/kaigai-fx/fixedFooter.js";
import parse from "html-react-parser"
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Import SVG
import icon_t_SVG1 from '@images/kaigai-fx/top_t_icon1.svg';

// 関数呼び出し
import { ProfileContents } from "@components/kaigai-fx/profile.js";
import { SeoContents } from "@components/kaigai-fx/seo-data.js" // Seo情報設定


const FxTermArchive = ({pageContext}) => {
  
  const myLine = pageContext.title;
  
  // Seo情報設定
  const pathName = 'term';
  const seoTag = SeoContents({Path:pathName, Title:pageContext.title, Slug:pageContext.slug});
  
  // プロフィール
  const profilePC = ProfileContents().PC 
  const profileMB = ProfileContents().MB

  // 選択判別CSS追加
  let allData = pageContext.allData;
  allData.forEach((result) => {
    if(result.node.slug === pageContext.slug){
      result.node.class = "select"
    } else {
      result.node.class = ""
    }
  })

  return (
    <>
      <Layout>
        {seoTag}
        <div className='term'>
          <div className={"md:max-w-6xl md:flex justify-between mx-auto pt-5 md:pt-12"}> 
            <div className="grow term_cont max-w-3xl pb-5 px-4  mb-10 md:pb-0 md:mb-0">
              <div className="text-left mb-3 mx-auto flex items-center">
                <p><LazyLoadImage alt="icon1" width="49.5" height="77.95" src={icon_t_SVG1}/></p>
                <h1 className="ml-5 font-semibold tracking-wide text-[24px] md:text-[56px] text-[#36A7C3] table" itemProp="headline">FX用語集</h1>
              </div>

              {/* モバイル あかさたなボタン */}
              <div className="block md:hidden term_sp_filed">
                {allData.map((result) =>
                  <a href={`/kaigai-fx/${result.node.slug}/`}>
                    <div className={result.node.class}>{result.node.title}</div>
                  </a>
                )}
              </div>

              {/* 用語コンテンツ */}
              <div className="max-w-xl w-full mx-auto md:pb-11 md:max-w-5xl mb-10">
                <h2>{myLine}</h2>
                {pageContext.data.map((result, index) =>
                  <>
                    <span id={`_${index + 1}`}></span>
                    <article>
                      <h3>{result.termName}</h3>
                      <p className="text-xs mb-10">{parse(result.termOverview)}</p>
                    </article>
                  </>
                )}
              </div>
            </div>

            {/* PC 目次リスト */}
            <div className="column2_filed px-4">
              <div className="sticky term_toc_mt -mt-[65px]">
                <div className="term_toc_filed hidden md:block mb-[20px]"> 
                  {allData.map((result) =>
                    <>
                      <a href={`/kaigai-fx/${result.node.slug}/`}>
                        <div className={result.node.class}>{result.node.title}</div>
                      </a>
                      {result.node.class === 'select'? 
                        <>
                          {result.node.smartCustomFields.map((item, index) => 
                            <a href={`#_${index + 1}`}><p>{item.termName}</p></a>
                          )}
                        </>
                      :<></>}
                    </>
                  )}
                </div>

                {/* PCプロフィール */}
                {profilePC}

              </div>
            </div>
          </div>

          {/* モバイル あかさたなボタン */}
          <div className="bg-[#F8F8F8] block md:hidden mb-[30px] pb-[30px] pt-[5px]">
            <div className="max-w-xs mx-auto  term_sp_filed block md:hdden">
              {allData.map((result) =>
                <a href={`/kaigai-fx/${result.node.slug}/`}>
                  <div className={result.node.class}>{result.node.title}</div>
                </a>
              )}
            </div>
          </div>

         {/* MBプロフィール */}
          {profileMB}
        </div>
        <FixedFoot fixPattern="nomal" item="" />
      </Layout>
    </>
  )
}
export default FxTermArchive